var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-accounts"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.success)?_c('div',{staticClass:"alert alert-success"},[_c('p',[_vm._v(_vm._s(_vm.success))])]):_vm._e(),_c('query-data-table',{ref:"paginateDealerAccounts",attrs:{"data-key":"paginateUsers","client-id":"auctionGraphqlClient","title":_vm.$it('accounts.list.title', 'Accounts'),"query":require('@/graphql/queries/users/PaginateUsers.gql'),"variables":{limit: 50},"row-height":71,"columns":_vm.columns,"filters":_vm.filters,"searchableFields":_vm.searchable},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var register = ref.register;
var selected = ref.selected;
return [_c('send-password-create-mail-action',{attrs:{"register":register,"selected":selected}}),_c('send-users-reminder-email-action',{attrs:{"register":register,"selected":selected}})]}},{key:"action-bar-buttons",fn:function(){return [_c('action-button',{attrs:{"buttonClasses":"action-bar-button","title":_vm.$it('accounts.create.title', 'Create user')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var register = ref.register;
return [_c('create-account-action',{attrs:{"register":register,"default-user-type":"back-office","available-user-types":_vm.userTypes,"single":"","allow-empty":""},on:{"done":_vm.onAccountCreated}})]}}])})]},proxy:true},{key:"displayName",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"row-title"},[_c('router-link',{attrs:{"to":{ name: 'inspections', query: { 'inspectorId': item.id }}}},[_vm._v(_vm._s(value))])],1)]}},{key:"email",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"row-line"},[_vm._v(_vm._s(value))])]}},{key:"addresses",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value.length > 0)?_c('div',{staticClass:"row-line cities"},[_vm._l((value),function(address){return [(address && address.entity)?_c('router-link',{attrs:{"to":{ name: 'branches-detail', params: { id: address.entity.id, branchId: address.id }}}},[_vm._v(" "+_vm._s(address.city)+" ")]):_vm._e()]})],2):_c('div')]}},{key:"language",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("language")(value))+" ")]}},{key:"boolean",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value)?_c('i',{staticClass:"fa fa-check-circle"}):_c('i',{staticClass:"fa fa-times-circle"})]}},{key:"inspectionsAmountTotal",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_vm._v(" "+_vm._s(item.openInspectionsAmount)+" / "+_vm._s(item.inspectionsAmount)+" "),(item.openInspectionsAmount === 0)?[_vm._v(" (0%) ")]:[_vm._v(" ("+_vm._s(_vm.$in(item.openInspectionsAmount / item.inspectionsAmount * 100, 'percentage'))+") ")]]}},{key:"appVersion",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"app-version",attrs:{"aria-label":_vm.getAppVersionMessage(item),"data-balloon-pos":"left"}},[(item.device !== null)?[(item.device.systemOs === 'Android')?_c('i',{staticClass:"fab fa-android"}):(item.device.systemOs === 'iOS')?_c('i',{staticClass:"fab fa-apple"}):_vm._e()]:_vm._e(),(item.device === null)?_c('i',{staticClass:"fa fa-minus-circle"}):(item.device && item.device.systemOsOutdated)?_c('i',{staticClass:"fas fa-ban"}):(item.device && item.device.appOutdated)?_c('i',{staticClass:"fas fa-clock"}):_vm._e(),_c('label',[_vm._v(_vm._s(value || '—'))])],2)]}},{key:"edit",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(_vm.$can('notifications-get'))?_c('button',{staticClass:"account-notifications",attrs:{"aria-label":_vm.$it('global.notifications', 'Notifications'),"data-balloon-pos":"left"},on:{"click":function($event){return _vm.notifications(item)}}},[_c('i',{staticClass:"fa fa-envelope"})]):_vm._e(),(_vm.canEditUser(item))?_c('div',[(_vm.$can('dealers-accounts-edit'))?_c('button',{staticClass:"account-edit",attrs:{"aria-label":_vm.$it('global.edit', 'Edit'),"data-balloon-pos":"left"},on:{"click":function($event){return _vm.editAccount(item)}}},[_c('i',{staticClass:"fa fa-pencil-alt"})]):_vm._e()]):_vm._e()]}}])}),(_vm.$can('dealers-accounts-edit'))?_c('edit-account-action',{ref:"editAccountModal",attrs:{"account":_vm.activeUser}}):_vm._e(),_c('notifications-modal',{ref:"notificationsModal",attrs:{"user":_vm.activeUser}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }