





























































































































import { Component, Vue } from 'vue-property-decorator';
import { Filter, FilterType } from '@/layouts/back-office/elements/filters/FilterMixin';
import { DataTableColumn, DataTableSearchable } from '@/layouts/back-office/elements/datatable/DataTableMixin';
import QueryDataTable from '@/layouts/back-office/elements/datatable/QueryDataTable.vue';
import SendPasswordCreateMailAction from '@/components/account/actions/SendPasswordCreateMailAction.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import LanguageSelect from '@/layouts/back-office/elements/input/InputLanguage.vue';
import EditAccountAction from '@/components/account/actions/EditAccountAction.vue';
import CreateAccountAction from '@/components/account/actions/CreateAccountAction.vue';
import ActionButton from '@/layouts/back-office/elements/actions/ActionButton.vue';
import {Account_account, PaginateDealerAccounts_paginateDealerAccounts_data} from '@/types/intrador';
import SendUsersReminderEmailAction from '@/components/dealer/actions/SendUsersReminderEmailAction.vue';
import {EntityType, User, UserSort} from '@/types/auction';
import {UserType} from '@/components/account/forms/UserForm.vue';
import {FilterSelectOption} from '@/layouts/back-office/elements/filters/SelectFilter.vue';
import NotificationsModal from '@/components/account/Modals/NotificationsModal.vue';

@Component({
  components: {
    NotificationsModal,
    ActionButton,
    CreateAccountAction,
    EditAccountAction,
    LanguageSelect,
    Modal,
    QueryDataTable,
    SendUsersReminderEmailAction,
    SpinnerButton,
    SendPasswordCreateMailAction,
  },
})
export default class Accounts extends Vue {

  private success: string | null = null;
  private activeUser: User|null = null;

  get account(): Account_account {
    return this.$store.getters.user;
  }

  protected get userTypes(): UserType[] {
    const userTypes: UserType[] = [];

    if (this.$can('entity-users-create')) {
      userTypes.push({
        key: 'back-office',
        entityType: EntityType.CURRENT,
        label: this.$it('accounts.user-type.back-office', 'Back office') as string,
        roles: ['4', '14'],
      });

      userTypes.push({
        key: 'inspector',
        entityType: EntityType.CURRENT,
        label: this.$it('accounts.user-type.internal-inspector', 'Inspector') as string,
        roles: ['2'],
      });
    }

    if (this.$can('bidders-users-create')) {
      userTypes.push({
        key: 'bidder',
        entityType: EntityType.BIDDER,
        label: this.$it('accounts.user-type.bidder', 'Bidder') as string,
        roles: ['15'],
      });
    }

    if (this.$can('appraisers-users-create')) {
      userTypes.push({
        key: 'appraiser',
        entityType: EntityType.VALUDATION_AGENCY,
        label: this.$it('accounts.user-type.valuation-agency', 'Appraiser') as string,
        roles: ['13'],
      });
    }

    return userTypes;
  }

  /**
   * The DataTable searchable
   */
  get searchable(): DataTableSearchable[] {
    return [
      {
        key: 'id',
        name: '#',
      },
      {
        key: 'name',
        name: this.$it('accounts.col.name.title', 'Name') as string,
      },
      {
        key: 'email',
        name: this.$it('accounts.col.email.title', 'Email') as string,
      },
      {
        key: 'entity.name',
        name: this.$it('accounts.col.dealer.title', 'Dealer') as string,
      },
    ];
  }

  /**
   * The DataTable filters
   */
  get filters(): Filter[] {
    const entityTypesOptions: FilterSelectOption[] = [];

    if (this.$can('entity-users-get')) {
      entityTypesOptions.push({
        key: EntityType.CURRENT,
        label: this.$it('accounts.user-type.back-office', 'Back office') as string,
      });
    }
    if (this.$can('dealers-users-get')) {
      entityTypesOptions.push({
        key: EntityType.DEALER,
        label: this.$it('accounts.user-type.dealers', 'Dealers') as string,
      });
    }
    if (this.$can('bidders-users-get')) {
      entityTypesOptions.push({
        key: EntityType.BIDDER,
        label: this.$it('accounts.user-type.bidders', 'Bidders') as string,
      });
    }
    if (this.$can('appraisers-users-get')) {
      entityTypesOptions.push({
        key: EntityType.VALUDATION_AGENCY,
        label: this.$it('accounts.user-type.appraisers', 'Appraisers') as string,
      });
    }

    return [
      {
        key: 'entityTypes',
        title: this.$it('accounts.filter.user-type.title', 'Users') as string,
        type: FilterType.Select,
        options: entityTypesOptions,
      },
    ];
  }

  /**
   * The DataTable columns
   */
  get columns(): DataTableColumn[] {
    return [
      {
        title: '#',
        key: 'id',
        sortable: UserSort.ID,
        class: 'data-table-col-fixed data-table-col-middle',
        width: 50,
      },

      {
        title: this.$it('accounts.col.name.title', 'Name') as string,
        key: 'name',
        sortable: UserSort.NAME,
        class: 'data-table-col-min data-table-col-middle',
        width: 140,
      },

      {
        title: this.$it('accounts.col.email.title', 'Email') as string,
        key: 'email',
        sortable: UserSort.EMAIL,
        class: 'data-table-col-min data-table-col-middle',
        width: 250,
      },

      // {
      //   title: this.$it('accounts.col.phone.title', 'Phone') as string,
      //   key: 'phone',
      //   sortable: true,
      //   class: 'data-table-col-middle',
      //   width: 100,
      // },

      {
        title: this.$it('accounts.col.locations.title', 'Locations') as string,
        key: 'addresses',
        sortable: UserSort.ENTITY_NAME,
        class: 'data-table-col-min data-table-col-middle',
        width: 300,
        permission: 'dealers-branches-get',
      },

      {
        title: this.$it('accounts.col.language.title', 'Language') as string,
        key: 'language',
        sortable:  UserSort.ENTITY_NAME,
        class: 'data-table-col-fixed data-table-col-center',
        width: 100,
      },

      {
        title: this.$it('accounts.col.created.title', 'Created') as string,
        key: 'hasPassword',
        class: 'data-table-col-fixed data-table-col-center',
        slot: 'boolean',
        width: 70,
      },

      {
        title: this.$it('accounts.col.bid-rights.title', 'Bid rights') as string,
        key: 'hasBidRights',
        permission: 'auctions-get',
        class: 'data-table-col-fixed data-table-col-center',
        slot: 'boolean',
        width: 100,
      },
      {
        title: this.$it('accounts.col.inspection-open.title', 'Insp. Open') as string,
        key: 'inspectionsAmountTotal',
        permission: 'inspections-get',
        class: 'data-table-col-fixed data-table-col-center',
        width: 100,
        tooltip: {
          message: this.$it('accounts.col.inspections-open.tooltip', 'Open inspections compared to the total amount of inspections for this user') as string,
          position: 'left',
        },
      },

      {
        title: this.$it('accounts.col.app-version.title', 'App version') as string,
        key: 'device.appVersion',
        class: 'data-table-col-fixed data-table-col-center',
        slot: 'appVersion',
        width: 100,
      },

      {
        key: 'edit',
        permission: 'entities-accounts-edit|dealers-accounts-edit',
        class: 'data-table-col-fixed data-table-col-center',
        width: 50,
        select: false,
      },
    ];
  }

  private canEditUser(user: PaginateDealerAccounts_paginateDealerAccounts_data): boolean {
    if (this.account.id === user.id) {
      return true;
    }

    if (this.account.entity!.id === user.entity!.id) {
      return this.$can('entities-accounts-edit');
    }

    return this.$can('dealers-accounts-edit');
  }

  private onAccountCreated() {
    const paginateDealerAccounts: QueryDataTable = this.$refs.paginateDealerAccounts as QueryDataTable;

    paginateDealerAccounts.refresh();
    this.success = this.$it('account.create.success.title', 'The account is successfully created') as string;
  }

  private notifications(item: User) {
    this.activeUser = item;

    const modal: Modal = this.$refs.notificationsModal as Modal;
    modal.open();
  }

  private editAccount(item: User) {
    this.activeUser = item;

    const modal: Modal = this.$refs.editAccountModal as Modal;

    modal.open();
  }

  private getAppVersionMessage(item: any) {
    if (item.device === null) {
      return this.$it('accounts.device.error.no-app-installed', 'This user has no app installed');
    } else if (item.device.systemOsOutdated) {
      return this.$it('accounts.device.error.os-outdated', 'We do not support the OS that is used');
    } else if (item.device.appOutdated) {
      return this.$it('accounts.device.error.app-outdated', 'The app of the user is outdated');
    } else {
      return this.$it('accounts.device.up-to-date', 'The app is up to date');
    }
  }
}
