
























import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import {PaginateNotificationsVariables, User} from '@/types/auction';
import QueryDataTable from '@/layouts/back-office/elements/datatable/QueryDataTable.vue';
import {DataTableColumn} from '@/layouts/back-office/elements/datatable/DataTableMixin';

@Component({
  components: {QueryDataTable, Modal},
})
export default class NotificationsModal extends Vue {
  @Prop() protected user?: User;

  protected get name(): string|null {
    if (!this.user) {
      return null;
    }
    return this.user.name ?? this.user.email;
  }

  protected get variables(): PaginateNotificationsVariables|null {
    if (!this.user) {
      return null;
    }
    return  {first: 5, toUsers: [this.user.id]};
  }

  protected get columns(): DataTableColumn[] {
    return [
      {
        title: 'Send at',
        key: 'sendAt',
      },
      {
        title: 'Name',
        key: 'name',
      },
    ];
  }

  public open() {
    const modal = this.$refs.modal as Modal;
    modal.open();
  }
}
