







































import { Component, Mixins } from 'vue-property-decorator';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import { InspectionStatus } from '@/types/intrador';

@Component({
  components: {
    ActionModal,
  },
})
export default class SendUsersReminderEmailAction extends Mixins(ActionMixin) {
  public title: string = this.$it('email.accounts.reminder.action.title', 'Send reminder mail') as string;
  public icon?: string = 'envelope-open-text';
  public permission?: string = 'dealers-accounts-send-inspection-reminder';

  private InspectionStatus = InspectionStatus;

  private get variables() {
    return {
      userIds: this.selected.map((selection: any) => selection.id),
    };
  }

  public open() {
    const modal = this.$refs.actionModal as ActionModal;
    modal.open();
  }

  public close() {
    const modal = this.$refs.actionModal as ActionModal;
    modal.close();
  }

  protected onSelect() {
    this.open();
  }

  protected done() {
    this.$emit('done');
  }
}
